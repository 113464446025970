<template>
	<div class="flex flex-col relative">
		<div class="h-auto filter mb-5">
			<div class="w-full flex items-center">
				<h1 class="text-xl h-6 font-extrabold sm:text-2xl">Роли Системы</h1>
			</div>
		</div>
		<div class="t-overflow rounded-t-lg overflow-x-auto h-auto bg-white shadow-default" :class="{ 'custom-w-table-144': !menu, 'custom-w-table-360': menu }">
			<table class="w-full">
				<thead>
					<tr>
						<template v-for="(item, ind) in fields">
							<th v-if="ind === 0" :key="'J' + ind" rowspan="2" class="px-6 py-4 min-w-80 border-b border-r border-gray-200 text-sm text-left leading-4 font-semibold text-gray-500">
								<p>{{ item.Name }}</p>
							</th>
							<th v-else colspan="2" :key="'J' + ind" class="px-6 py-4 whitespace-no-wrap border-b border-r border-gray-200 text-sm text-center font-semibold text-gray-500">
								<p>{{ item.Name }}</p>
							</th>
						</template>
					</tr>
					<tr>
						<template v-for="ind in fields.length - 1">
							<th :key="'SV' + ind" class="px-6 py-4 border-b border-r border-gray-200 text-sm text-left leading-4 font-semibold text-gray-500">
								<p>Только просмотр</p>
							</th>
							<th :key="'SR' + ind" class="px-6 py-4 border-b border-r border-gray-200 text-sm text-left leading-4 font-semibold text-gray-500">
								<p>Редактирование</p>
							</th>
						</template>
					</tr>
				</thead>
				<tbody v-for="(item, indx) in dataForms" :key="'A' + indx">
					<tr>
						<td colspan="19" class="px-6 py-4 font-extrabold text-lg whitespace-no-wrap border-b border-r text-left border-gray-200 leading-5 text-black">{{ item.name }}</td>
					</tr>
					<tr v-for="(subItem, index) in item.requests" :key="'S' + index">
						<td class="px-6 min-w-80 py-4 font-normal text-sm whitespace-no-wrap border-b border-r border-gray-200 leading-5 text-gray-500 text-left">{{ subItem.description }}</td>
						<template v-for="(role, key) in subItem.roles">
							<td :key="'E' + key" class="px-6 py-4 font-normal text-sm whitespace-no-wrap border-b border-r border-gray-200 leading-5 text-gray-500 text-center">
								<input
									type="checkbox"
									:disabled="getAuthUser.roles[0] !== 'Support'"
									v-model="role.isViewActive"
									@change="changeAction(role, subItem.requestId, 0, role.isViewActive)"
								/>
							</td>
							<td :key="'V' + key" class="px-6 py-4 font-normal text-sm whitespace-no-wrap border-b border-r border-gray-200 leading-5 text-gray-500 text-center">
								<input
									type="checkbox"
									:disabled="getAuthUser.roles[0] !== 'Support'"
									v-model="role.isEditActive"
									@change="changeAction(role, subItem.requestId, 2, role.isEditActive)"
								/>
							</td>
						</template>
					</tr>
				</tbody>
			</table>
		</div>
		<div class="h-20 rounded-b-lg bg-white filter shadow-default"></div>
	</div>
</template>

<script>
import actions from '../store/actions';
import { getters } from '@/store/store';

export default {
	name: 'TableRoles',
	data() {
		return {
			filterOpen: null,
			filterBy: null,
			fields: [
				{
					Name: 'Действия/Роли',
					fieldName: 'actionRoles',
					filterType: 2,
					filterValueType: 1,
					value1: '',
					value2: null,
					sortBy: null,
					label1: 'По возрастанию (А-Я)',
					label2: 'По убыванию (Я-А)'
				},
				{
					Name: 'Сотрудник СТП',
					fieldName: 'Support',
					filterType: 1,
					filterValueType: 1,
					value1: '',
					value2: null,
					sortBy: null,
					label1: 'По возрастанию',
					label2: 'По убыванию'
				},
				{
					Name: 'Сотрудник Минздрава',
					fieldName: 'minzdravMember',
					filterType: 1,
					filterValueType: 1,
					value1: '',
					value2: null,
					sortBy: null,
					label1: 'По возрастанию',
					label2: 'По убыванию'
				},
				{
					Name: 'Сотрудник Минздрава-Наблюдатель',
					fieldName: 'minzdravWatcher',
					filterType: 1,
					filterValueType: 1,
					value1: '',
					value2: null,
					sortBy: null,
					label1: 'По возрастанию',
					label2: 'По убыванию'
				},
				{
					Name: 'Оператор бумажных анкет',
					fieldName: 'paperFormOperator',
					filterType: 1,
					filterValueType: 1,
					value1: '',
					value2: null,
					sortBy: null,
					label1: 'По возрастанию',
					label2: 'По убыванию'
				},
				{
					Name: 'Сотрудник ОГВ',
					fieldName: 'ogvMember',
					filterType: 1,
					filterValueType: 1,
					value1: '',
					value2: null,
					sortBy: null,
					label1: 'По возрастанию',
					label2: 'По убыванию'
				},
				{
					Name: 'Сотрудник ОГВ-Наблюдатель',
					fieldName: 'ogvWatcher',
					filterType: 1,
					filterValueType: 1,
					value1: '',
					value2: null,
					sortBy: null,
					label1: 'По возрастанию',
					label2: 'По убыванию'
				},
				{
					Name: 'Председатель ОС',
					fieldName: 'osChairman',
					filterType: 1,
					filterValueType: 1,
					value1: '',
					value2: null,
					sortBy: null,
					label1: 'По возрастанию',
					label2: 'По убыванию'
				},
				{
					Name: 'Секретарь ОС',
					fieldName: 'osSecretary',
					filterType: 1,
					filterValueType: 1,
					value1: '',
					value2: null,
					sortBy: null,
					label1: 'По возрастанию',
					label2: 'По убыванию'
				},
				{
					Name: 'Член ОС',
					fieldName: 'osMember',
					filterType: 1,
					filterValueType: 1,
					value1: '',
					value2: null,
					sortBy: null,
					label1: 'По возрастанию',
					label2: 'По убыванию'
				}
			],
			dataForms: []
		};
	},
	computed: {
		...getters
	},
	methods: {
		...actions,
		getData() {
			this.loadingTable = true;
			this.getGroupedRoles().then((res) => {
				this.dataForms = res.data;
				this.loadingTable = false;
			});
			// api call
		},
		switchFilterVisibility(key) {
			this.filterOpen = this.filterOpen === key ? null : key;
		},
		changeAction(item, id, type, active) {
			let params = {
				requestId: id,
				actioType: type,
				roleId: item.roleId,
				isActive: active
			};
			this.updateRequestRoleLink(params);
			// api call
		}
	},
	mounted() {
		this.getData();
	}
};
</script>

<style scoped>
.custom-w-table-144 {
	max-width: calc(100vw - 144px - 3rem);
}

.custom-w-table-360 {
	max-width: calc(100vw - 360px - 3rem);
}
</style>
